import { Link } from "gatsby";
import * as React from "react";
import Layout from "../components/Layout";
import Wrapper from "../components/styles/Wrapper";

const NotFoundPage = () => {
  return (
    <Layout>
      <Wrapper>
        <p>
          Sorry{" "}
          <span role="img" aria-label="Pensive emoji">
            😔
          </span>{" "}
          we couldn’t find what you were looking for.
          <br />
        </p>
        <Link to="/">Go home</Link>.
      </Wrapper>
    </Layout>
  );
};

export default NotFoundPage;
